import {get, post} from './index'

/**
 * 获取事件列表
 * @param data
 * @returns {Promise<unknown>}
 */
export const list = data => {
    return get('/system/event/list', data).then(res => res)
}

/**
 * 获取事件详情
 * @param data
 * @returns {Promise<unknown>}
 */
export const getDetail = id => {
    return get(`/system/event/getDetail/${id}`).then(res => res)
}

/**
 * 保存事件定义
 * @param data
 * @returns {Promise<unknown>}
 */
export const operateEvent = data => {
    return post('/system/event/operateEvent', data).then(res => res)
}

/**
 * 删除系统事件
 *
 * @param id
 * @returns {Promise<unknown>}
 */
export const deleteEvent = id => {
    return post(`/system/event/deleteEvent/${id}`).then(res => res)
}

/**
 * 获取所有下拉选项值
 *
 * @param data
 * @returns {Promise<unknown>}
 */
export const getDropdownLabel = data => {
    return post('/system/event/getDropdownLabel', data).then(res => res)
}
