<template>
  <div class="page-main" v-loading="loading">
    <el-form ref="eventForm" :model="info" :rules="eventRules" label-width="120px">
      <el-row>
        <el-col :span="12">
          <el-form-item prop="eventType" label="任务类型">
            <el-select v-model="info.eventType" placeholder="请选择">
              <el-option
                  v-for="item in eventTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item prop="templateId" label="模板名称">
            <el-select v-model="info.templateId" placeholder="请选择">
              <el-option
                  v-for="item in templateIds"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item prop="eventTo" label="邮件接收角色">
            <el-select
                v-model="info.eventTo"
                multiple
                filterable
                allow-create
                default-first-option
                placeholder="请选择接收角色">
              <el-option
                  v-for="item in roleIds"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item prop="eventCc" label="邮件抄送角色">
            <el-select
                v-model="info.eventCc"
                multiple
                filterable
                allow-create
                default-first-option
                placeholder="请选择抄送角色">
              <el-option
                  v-for="item in roleIds"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item prop="triggerCron" label="定时发送">
            <el-input v-model.trim="info.triggerCron"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-button type="text" @click="newStrategy">
            点击新增
          </el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <div class="el-button-event">
            <el-button @click="handleCancel">取消</el-button>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="el-button-event">
            <el-button v-loading="saveBtnLoading" @click="handleSaveContent" type="primary">保存
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>

    <el-dialog title="Cron表达式生成器" :visible.sync="openCron" append-to-body destroy-on-close class="scrollbar">
      <crontab @hide="openCron=false" @fill="crontabFill" :expression="info.triggerCron"></crontab>
    </el-dialog>
  </div>
</template>

<script>
import {getDetail, getDropdownLabel, operateEvent} from '@/service/event'
import Crontab from '@/components/Crontab'

export default {
  name: 'EventEdit',
  components: {Crontab},
  props: {
    eventDetail: {
      type: Object
    }
  },
  watch: {},
  computed: {},
  data() {
    return {
      eventRules: {
        eventType: [
          {required: true, message: '请选择事件类型!', trigger: 'blur'}
        ],
        templateId: [
          {required: true, message: '请选择模板名称!', trigger: 'blur'}
        ],
        eventTo: [
          {required: true, message: '请选择收件角色!', trigger: 'change'}
        ]
      },
      loading: false,
      info: {
        id: 0,
        triggerCron: '',
        templateId: '',
        eventTo: [],
        eventCc: [],
        eventType: ''
      },
      roleIds: [],
      templateIds: [],
      eventTypes: [],
      saveBtnLoading: false,
      // 是否显示Cron表达式弹出层
      openCron: false,
      // 传入的表达式
      // expression: "",
    }
  },
  created() {
    this.getDicts('sys-event-type').then(({data}) => this.eventTypes = data)
    this.handleDropdownOption() // 获取所有的下拉选项值
    this.handleEventDetail() //获取回显信息
  },
  methods: {
    /** 确定后回传值 */
    crontabFill(value) {
      this.info.triggerCron = value
    },
    async handleEventDetail() {
      if (this.eventDetail.id > 0) {
        const {success, data} = await getDetail(this.eventDetail.id)
        if (success) {
          this.info = data
        }
      }
    },
    async handleSaveContent() {
      // 校验其他字段
      let fields = ['eventType', 'templateId','eventTo']
      let fieldError = []
      this.$refs['eventForm'].validateField(fields, (errorMsg) => {
        if (errorMsg != '') {
          fieldError.push(errorMsg)
        }
      })
      if (fieldError.length > 0) return
      this.saveBtnLoading = true
      if (this.eventDetail.id > 0) {  //修改
        this.info.id = this.eventDetail.id
      }
      const {success} = await operateEvent(this.info)
      const message = this.eventDetail.id > 0 ? '修改' : '新增'
      this.saveBtnLoading = false
      if (success) {
        this.toast(`${message}邮件模板信息成功`, 'success', () => this.handleCancel('success'))
      } else {
        this.toast(`${message}邮件模板信息失败`, 'error')
      }
    },
    // 点击取消的回调
    handleCancel(status) {
      this.$emit('handleCancelEditDialog', status)
    },
    newStrategy() {
      this.expression = this.info.triggerCron;
      this.openCron = true;
    },
    async handleDropdownOption() {
      const {success, data} = await getDropdownLabel()
      if (success) {
        this.roleIds = data.roleLabels
        this.templateIds = data.templateNameLabels
      }
    }
  }
}
</script>
<style scoped lang="scss">
.scrollbar {
  ::v-deep .el-dialog__body {
    padding: 30px 20px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }

  ::v-deep .el-input {
    width: 100%;
  }
}

.page-main {
  padding: 20px;
  height: 350px;
  overflow: auto;

  ::v-deep .el-form-item {
    margin-bottom: 22px;
  }

  ::v-deep .el-form-item__error {
    top: 100%;
  }

  .emailContent {
    margin-bottom: 20px;
  }

  ::v-deep .el-input {
    width: 100%;
  }

  ::v-deep .el-form-item__label, ::v-deep .el-form-item__content {
    height: initial;
    line-height: initial;

    .el-select {
      display: flex;
    }
  }

  .el-button-event {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }
}
</style>
